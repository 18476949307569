
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import ChatBox from '@/components/dropdown/ChatBox.vue';

  export default defineComponent({
    name: 'ticket-listing',
    components: {
      ChatBox,
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();
      const messagesList = ref([]);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const filterQuery = ref({
        qaustionType: undefined,
        typeId: undefined,
        quastionId: undefined,
      });

      const searchItems = async (inputText) => {
        await store.dispatch(Actions.UPDATE_TICKET_FILTER, filterQuery.value);
        await store.dispatch(Actions.GET_TICKETS);
      };
      const staticQuestions = ref();

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('Ticket Listing'), [
          translate('operations'),
        ]);

        onBeforeUnmount(() => {
          store.dispatch(Actions.RESET_TICKET_STORE);
        });
        loading.value = true;
        await store.dispatch(Actions.GET_TICKETS);
        staticQuestions.value = await store.dispatch(
          Actions.GET_ALL_STATIC_QUESTIONS
        );
        console.log(staticQuestions.value);
        loading.value = false;
      });
      const loading = ref(false);
      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.QUESTION_TYPE,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const questionType = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.QUESTION_TYPE;
      });
      const tableHeader = ref([
        {
          name: translate('title'),
          key: 'title',
          sortable: true,
        },
        {
          name: translate('customer '),
          key: 'customerName',
          sortable: true,
        },
        {
          name: translate('question '),
          key: 'quastionName',
          sortable: true,
        },

        {
          name: translate('status'),
          key: 'status',
          sortable: true,
        },

        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const deleteTicket = async (ticket) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_TICKET, ticket.id);
          await store.dispatch(Actions.GET_TICKETS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };
      const toggleTicket = async (ticket) => {
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        ticket.status = !ticket.status;
        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.UPDATE_TICKET, {
            data: ticket,
            id: ticket.id,
          });
          await store.dispatch(Actions.GET_TICKETS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          }
        }
      };

      const filterTickets = (key, value) => {
        filterQuery.value[key] = value;
        if (filterQuery.value.qaustionType !== 'static') {
          filterQuery.value['typeId'] = undefined;
        }
        filteredQuestions();
        searchItems(questionType);
      };

      const filteredQuestions = () => {
        if (filterQuery.value.qaustionType == 'static') {
          const selectedQuestion = staticQuestions.value.find(
            (question) => question.id === filterQuery.value.quastionId
          );
          console.log(selectedQuestion);
          if (selectedQuestion && selectedQuestion.slug === 'Other') {
            filterQuery.value.quastionId = undefined;
          }
          return staticQuestions.value.filter(
            (question) => question.slug !== 'Other'
          );
        }
        return staticQuestions.value;
      };

      const editTicket = (id) => {
        router.push({ name: 'ticket-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_TICKET_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_TICKETS);
        loading.value = false;
      };
      return {
        tableData: computed(() => store.getters.ticketList),
        tableHeader,
        inputText: '',
        searchItems,
        deleteTicket,
        toggleTicket,
        translate,
        totalItems: computed(() => store.getters.ticketListCount),
        pageChanged,
        goTo,
        editTicket,
        loading,
        can,
        filterTickets,
        filterQuery,
        questionType,
        messagesList,
        staticQuestions,
        filteredQuestions,
      };
    },
  });
