
  import {
    defineComponent,
    ref,
    onMounted,
    nextTick,
    watch,
    defineProps,
  } from 'vue';
  import MessageIn from '@/components/messenger-parts/MessageIn.vue';
  import MessageOut from '@/components/messenger-parts/MessageOut.vue';
  import Dropdown4 from '@/components/dropdown/Dropdown4.vue';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';

  interface KTMessage {
    type: string;
    name?: string;
    image: string;
    time: string;
    text: string;
  }

  export default defineComponent({
    emit: ['clicked'],
    name: 'upgrade-to-pro',
    components: {
      MessageIn,
      MessageOut,
    },
    props: {
      id: {
        type: String,
        default: '',
      },
    },

    setup: function (props, { emit }) {
      const messagesRef = ref<null | HTMLElement>(null);
      const messagesInRef = ref<null | HTMLElement>(null);
      const messagesOutRef = ref<null | HTMLElement>(null);
      const count = ref(1);
      const store = useStore();

      const messages = ref<Array<KTMessage>>([
        {
          type: 'in',
          name: 'Brian Cox',
          image: 'media/avatars/300-25.jpg',
          time: '5 Hours',
          text: 'How likely are you to recommend our company to your friends and family ?',
        },
        {
          type: 'out',
          image: 'media/avatars/300-1.jpg',
          time: '2 Hours',
          text: 'Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.',
        },
        {
          type: 'in',
          name: 'Brian Cox',
          image: 'media/avatars/300-25.jpg',
          time: '2 Hour',
          text: 'Ok, Understood!',
        },
        {
          type: 'out',
          image: 'media/avatars/300-1.jpg',
          time: '2 Hours',
          text: 'You’ll receive notifications for all issues, pull requests!',
        },
        {
          type: 'in',
          name: 'Brian Cox',
          image: 'media/avatars/300-25.jpg',
          time: '1 Hour',
          text: 'You can unwatch this repository immediately by clicking here: Keenthemes.com',
        },
        {
          type: 'out',
          image: 'media/avatars/300-1.jpg',
          time: '4 mins',
          text: 'Most purchased Business courses during this sale!',
        },
        {
          type: 'in',
          name: 'Brian Cox',
          image: 'media/avatars/300-25.jpg',
          time: '2 mins',
          text: 'Company BBQ to celebrate the last quater achievements and goals. Food and drinks provided',
        },
      ]);

      const newMessageText = ref('');
      const showMessage = ref(false);
      const messageList = ref<any>([]);
      const chatBox = ref(null);
      const messageContainer = ref<null | HTMLElement>(null);

      const handleClickOutside = (event) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (chatBox.value && !chatBox.value!.contains(event.target)) {
          showMessage.value = false;
          document.body.style.overflow = 'auto';
          count.value = 1;
          store.dispatch(Actions.UPDATE_TICKET_MESSAGE_CURRENT_PAGE, 1);
          stopScrolling.value = false;
        }
      };

      onMounted(() => {
        document.addEventListener('click', handleClickOutside);
      });
      const stopScrolling = ref(false);
      const handleScroll = async (event) => {
        console.log('scrolling', stopScrolling.value);
        if (stopScrolling.value) return;

        const { scrollTop } = event.target;
        if (scrollTop === 0) {
          store.dispatch(
            Actions.UPDATE_TICKET_MESSAGE_CURRENT_PAGE,
            (count.value += 1)
          );

          const data = await store.dispatch(
            Actions.GET_TICKETS_MESSAGES,
            props.id
          );
          if (data.data.length === 0) {
            stopScrolling.value = true;
            return;
          }

          data.data.forEach((item) => {
            const existingDate = messageList.value.find(
              (message) => message.date === item.date
            );

            if (existingDate) {
              // If the date exists, combine the messages
              existingDate.messgaes = [
                ...item.messgaes.reverse(),
                ...existingDate.messgaes,
              ];
            } else {
              // If the date doesn't exist, add the new item
              messageList.value.unshift(item);
            }
          });

          // After updating, set the scroll position
          if (messageContainer.value) {
            messageContainer.value.scrollTop = 400;
          }
        }
      };

      const getMessages = async () => {
        showMessage.value = false;

        const data = await store.dispatch(
          Actions.GET_TICKETS_MESSAGES,
          props.id
        );
        messageList.value = data.data.reverse();
        messageList.value.map((item) => {
          item.messgaes = item.messgaes.reverse();
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.querySelector(`.drawer${props.id}`).style.width = '500px';
        const messagesContainer = document.querySelector(`.a${props.id}`);
        messagesContainer!.addEventListener('scroll', handleScroll);
        setTimeout(() => {
          if (messageContainer.value) {
            messageContainer.value.scrollTop =
              messageContainer.value.scrollHeight;
          }
        }, 1);
        showMessage.value = true;
        document.body.style.overflow = 'hidden';
      };

      const addNewMessage = async () => {
        if (!newMessageText.value) {
          return;
        }
        await store.dispatch(Actions.CREATE_TICKETS_MESSAGES, {
          id: props.id,
          data: { content: newMessageText.value },
        });
        messageList.value.push({
          date: '',
          messgaes: [
            {
              createdByType: 'admin',
              time: 'Just now',
              content: newMessageText.value,
            },
          ],
        });
        newMessageText.value = '';
        // should make api request here

        setTimeout(() => {
          if (messageContainer.value) {
            messageContainer.value.scrollTop =
              messageContainer.value.scrollHeight;
          }
        }, 1);
      };

      reinitializeComponents();
      const clicked = () => {
        emit('clicked');
      };

      return {
        messages,
        messagesRef,
        newMessageText,
        addNewMessage,
        messagesInRef,
        messagesOutRef,
        clicked,
        messageList,
        count,
        getMessages,
        showMessage,
        chatBox,
        messageContainer,
      };
    },
  });
